
import { createReducer } from "@reduxjs/toolkit";

export const adminDashboardReducer = createReducer({}, builder => {

    builder
        .addCase("getAdminUserAndTrxCountRequest", state => {
            state.loading = true;
        })
        .addCase("getAdminUserAndTrxCountSuccess", (state, action) => {
            state.loading = false;
            state.userAndTrxdata = {
                users: action.payload.users,
                transactions: action.payload.orders
            };
        })
        .addCase("getAdminUserAndTrxCountFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        .addCase("getRatioOfTxRequest", state => {
            state.loading = true;
        })
        .addCase("getRatioOfTxSuccess", (state, action) => {
            state.loading = false;
            state.ratioOfTx = action.payload;
        })
        .addCase("getRatioOfTxFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        .addCase("getAllTransactionsRequestAdmin", state => {
            state.loading = true;
        })
        .addCase("getAllTransactionsSuccessAdmin", (state, action) => {
            state.loading = false;
            state.allTransactions = action.payload;
        })
        .addCase("getAllTransactionsFailAdmin", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

})