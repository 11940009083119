import React, { useEffect, Suspense, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import ProtectedRoute from "./ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../redux/actions/userAction";
import { Toaster } from "react-hot-toast";
import Loader from "../layout/loader/Index";
import Wizard from "../pages/panel/subscription/userBuild/addwallet/Wizard.js";
import VerifyWizard from "../pages/panel/subscription/userBuild/addwallet/VerifyWizard.js";
import Index from "../pages/panel/subscription/userBuild/orderMicros/Index.js";
import CoinDashboard from "../pages/panel/subscription/admin/coinDetails/CoinDashboard.js";


const AddCurrency = lazy(() => import('../pages/panel/subscription/admin/coinMicros/Addcurrency.js'))
const PayoutSettings = lazy(() => import("../pages/panel/subscription/PayoutSettings"));
const Homepage = lazy(() => import("../pages/Homepage"));
const Analytics = lazy(() => import("../pages/Analytics"));
const SubsDashboard = lazy(() => import("../pages/panel/subscription/Index"));
const SubsSubscriptions = lazy(() => import("../pages/panel/subscription/Subscriptions"));
const SubsSubscriptionDetails = lazy(() => import("../pages/panel/subscription/SubscriptionDetails"));
const SubsTickets = lazy(() => import("../pages/panel/subscription/Tickets"));
const SubsTicketDetails = lazy(() => import("../pages/panel/subscription/TicketDetails"));
const SubsSupport = lazy(() => import("../pages/panel/subscription/Support"));
const SubsSupportTopics = lazy(() => import("../pages/panel/subscription/SupportTopics"));
const SubsSupportTopicDetails = lazy(() => import("../pages/panel/subscription/SupportTopicDetails"));

const SubsProfileLayout = lazy(() => import("../pages/panel/subscription/ProfileLayout"));
const SubsProfile = lazy(() => import("../pages/panel/subscription/Profile"));
const SubsProfileActivity = lazy(() => import("../pages/panel/subscription/ProfileActivity"));
const SubsProfileBilling = lazy(() => import("../pages/panel/subscription/ProfileBilling"));
const SubsProfileNotify = lazy(() => import("../pages/panel/subscription/ProfileNotify"));
const SubsProfileSetting = lazy(() => import("../pages/panel/subscription/ProfileSetting"));
const SubsPricing = lazy(() => import("../pages/panel/subscription/Pricing"));

const SubsInvoices = lazy(() => import("../pages/panel/subscription/Invoices"));
const SubsInvoiceDetails = lazy(() => import("../pages/panel/subscription/InvoiceDetails"));
const SubsInvoicePrint = lazy(() => import("../pages/panel/subscription/InvoicePrint"));
const SubsFaqs = lazy(() => import("../pages/panel/subscription/Faqs"));

const SubsContact = lazy(() => import("../pages/panel/subscription/Contact"));

const Blank = lazy(() => import("../pages/others/Blank"));
const Faq = lazy(() => import("../pages/others/Faq"));
const Regularv1 = lazy(() => import("../pages/others/Regular-1"));
const Regularv2 = lazy(() => import("../pages/others/Regular-2"));
const Terms = lazy(() => import("../pages/others/Terms"));
const BasicTable = lazy(() => import("../pages/components/table/BasicTable"));
const DataTablePage = lazy(() => import("../pages/components/table/DataTable"));
const SpecialTablePage = lazy(() => import("../pages/components/table/SpecialTable"));
const ChartPage = lazy(() => import("../pages/components/charts/Charts"));
const EmailTemplate = lazy(() => import("../pages/components/email-template/Email"));
const NioIconPage = lazy(() => import("../pages/components/crafted-icons/NioIcon"));
const SVGIconPage = lazy(() => import("../pages/components/crafted-icons/SvgIcons"));


const ProjectListPage = lazy(() => import("../pages/pre-built/projects/ProjectList"));
const UserListRegular = lazy(() => import("../pages/pre-built/user-manage/UserListRegular"));
const UserListCompact = lazy(() => import("../pages/panel/subscription/admin/user/UserListCompact.js"))
const UserContactCard = lazy(() => import("../pages/pre-built/user-manage/UserContactCard"));
const UserDetails = lazy(() => import("../pages/pre-built/user-manage/UserDetailsRegular"));
// const UserListCompact = lazy(() => import("../pages/pre-built/user-manage/UserListCompact"));
const UserProfileLayout = lazy(() => import("../pages/pre-built/user-manage/UserProfileLayout"));
const UserProfileRegular = lazy(() => import("../pages/pre-built/user-manage/UserProfileRegular"));
const UserProfileSetting = lazy(() => import("../pages/pre-built/user-manage/UserProfileSetting"));
const UserProfileNotification = lazy(() => import("../pages/pre-built/user-manage/UserProfileNotification"));
const UserProfileActivity = lazy(() => import("../pages/pre-built/user-manage/UserProfileActivity"));
const ProductCard = lazy(() => import("../pages/pre-built/products/ProductCard"));
const ProductList = lazy(() => import("../pages/pre-built/products/ProductList"));
const ProductDetails = lazy(() => import("../pages/pre-built/products/ProductDetails"));
const InvoiceList = lazy(() => import("../pages/pre-built/order-invoice/InvoiceList"));
const InvoiceDetails = lazy(() => import("../pages/pre-built/order-invoice/InvoiceDetails"));
const PaymentHistory = lazy(() => import("../pages/pre-built/order-invoice/PaymentHistory"));
const InvoicePrint = lazy(() => import("../pages/pre-built/order-invoice/InvoicePrint"));
const PricingTable = lazy(() => import("../pages/pre-built/pricing-table/PricingTable"));
const GalleryPreview = lazy(() => import("../pages/pre-built/gallery/GalleryCardPreview"));
const KnobPreview = lazy(() => import("../pages/components/charts/KnobPreview"));
const Error404Classic = lazy(() => import("../pages/error/404-classic"));
const Error404Modern = lazy(() => import("../pages/error/404-modern"));
const Error504Modern = lazy(() => import("../pages/error/504-modern"));
const Error504Classic = lazy(() => import("../pages/error/504-classic"));

const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const Success = lazy(() => import("../pages/auth/Success"));

const Layout = lazy(() => import("../layout/Index"));
const LayoutNoSidebar = lazy(() => import("../layout/Index-nosidebar"));
const LayoutAuth = lazy(() => import("../layout/Index-authsidebar"));
const LayoutSubscription = lazy(() => import("../layout/Index-subscription"));
const PayoutSettingsLayout = lazy(() => import("../layout/Index-payoutLayout.js"));

const Pages = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, isAuthenticated, user } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(loadUser())
  }, [dispatch, location])

  return loading ? <Loader /> : (
    <>

      <Toaster position="bottom-center" />
      <Suspense fallback={<Loader />} >
        <Routes>

          <Route element={<LayoutAuth />}>
            <Route path="auth-success" element={<Success />}></Route>
            <Route path="auth-reset" element={<ForgotPassword />}></Route>
            <Route path="auth-register" element={<Register />}></Route>
            <Route path="auth-login" element={<Login />}></Route>
          </Route>

          <Route element={<LayoutSubscription />}>
            <Route
              path="/"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <SubsDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="subscriptions"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <SubsSubscriptions />
                </ProtectedRoute>
              } />

            <Route element={<PayoutSettingsLayout />}>
              <Route
                path='payout-settings'
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <PayoutSettings />
                  </ProtectedRoute>}
              />


              <Route
                path='payout-settings/verify/:walletId'
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <VerifyWizard />
                  </ProtectedRoute>}
              />


              <Route
                path='payout-settings/new/:coinId'
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Wizard />
                  </ProtectedRoute>}
              />
            </Route>

            {
              /* 
              <Route
                path=''
                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                  </ProtectedRoute>}
                  /> 
                  */
            }

            <Route
              path="payments"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  {/* <SubsPayments /> */}
                  <Index />
                </ProtectedRoute>
              }
            />


            {/* <Route
              path="downloads"
              element={<ProtectedRoute isAuthenticated={isAuthenticated}>
                <SubsDownloads />
              </ProtectedRoute>
              }
            /> */}

            <Route element={<SubsProfileLayout />}>

              <Route
                path="profile"
                element={<ProtectedRoute isAuthenticated={isAuthenticated}>
                  <SubsProfile />
                </ProtectedRoute>}
              />

              <Route
                path="profile-billing"
                element={<ProtectedRoute isAuthenticated={isAuthenticated}>
                  <SubsProfileBilling />
                </ProtectedRoute>}
              />


              <Route
                path="profile-notify"
                element={<ProtectedRoute isAuthenticated={isAuthenticated}>
                  <SubsProfileNotify />
                </ProtectedRoute>}
              />

              <Route
                path="profile-setting"
                element={<ProtectedRoute isAuthenticated={isAuthenticated}>
                  <SubsProfileSetting />
                </ProtectedRoute>}
              />
            </Route>

            <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
              <Route path="profile-activity" element={<SubsProfileActivity />} />
              <Route path="pricing" element={<SubsPricing />} />
              <Route path="invoices" element={<SubsInvoices />} />
              <Route path="invoice-details/:invoiceId" element={<SubsInvoiceDetails />} />
              <Route path="faqs" element={<SubsFaqs />} />
              <Route path="contact" element={<SubsContact />} />

              <Route path="subscription-details/:subscriptionId" element={<SubsSubscriptionDetails />} />
              <Route path="tickets" element={<SubsTickets />} />
              <Route path="ticket-details/:ticketId" element={<SubsTicketDetails />} />
              <Route path="support" element={<SubsSupport />} />

              <Route path="support/:topicSlug" element={<SubsSupportTopics />} />
              <Route path="support/:topicSlug/:questionId" element={<SubsSupportTopicDetails />} />

            </Route>

          </Route>


          <Route element={<Layout />}>

            <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} adminOnly={true} admin={user?.role === 'admin' ? true : false} />}>

              <Route path="admin/home" element={<Homepage />} />
              <Route path="admin/analytics" element={<Analytics />} />
              <Route path="_blank" element={<Blank />} />

              {/* <Route path="project-card" element={<ProjectCardPage />}></Route> */}
              <Route path="admin/project-card" element={<AddCurrency />} />
              <Route path="admin/project-list" element={<ProjectListPage />} />
              <Route path="admin/coindashboard/:coinId" element={<CoinDashboard />} />

              <Route element={<UserContextProvider />}>
                <Route path="admin/user-list-regular" element={<UserListRegular />} />
                <Route path="admin/user-list-compact" element={<UserListCompact />} />
                <Route path="admin/userlist" element={<UserListCompact />} />
                <Route path="admin/user-contact-card" element={<UserContactCard />} />
                <Route path="admin/user-details-regular/:userId" element={<UserDetails />} />
              </Route>

              <Route element={<UserProfileLayout />}>
                <Route path="admin/user-profile-notification" element={<UserProfileNotification />} />
                <Route path="admin/user-profile-regular" element={<UserProfileRegular />} />
                <Route path="admin/user-profile-activity" element={<UserProfileActivity />} />
                <Route path="admin/user-profile-setting" element={<UserProfileSetting />} />
              </Route>

              <Route element={<ProductContextProvider />}>
                <Route path="admin/product-list" element={<ProductList />} />
                <Route path="admin/product-card" element={<ProductCard />} />
                <Route path="admin/product-details/:productId" element={<ProductDetails />} />
              </Route>

              <Route path="admin/history-payment" element={<PaymentHistory />} />
              <Route path="admin/invoice-list" element={<InvoiceList />} />
              <Route path="admin/invoice-details/:invoiceId" element={<InvoiceDetails />} />
              <Route path="admin/pricing-table" element={<PricingTable />} />
              <Route path="admin/image-gallery" element={<GalleryPreview />} />

              <Route path="pages">
                <Route path="terms-policy" element={<Terms />} />
                <Route path="faq" element={<Faq />}></Route>
                <Route path="regular-v1" element={<Regularv1 />} />
                <Route path="regular-v2" element={<Regularv2 />} />
              </Route>

              <Route path="charts">
                <Route path="chartjs" element={<ChartPage />} />
                <Route path="knobs" element={<KnobPreview />} />
              </Route>

              <Route path="table-basic" element={<BasicTable />} />
              <Route path="table-datatable" element={<DataTablePage />} />
              <Route path="table-special" element={<SpecialTablePage />} />
              <Route path="email-template" element={<EmailTemplate />} />
              <Route path="nioicon" element={<NioIconPage />} />
              <Route path="svg-icons" element={<SVGIconPage />} />
            </Route>
          </Route>

          <Route element={<LayoutNoSidebar />}>
            <Route path="errors">
              <Route path="404-modern" element={<Error404Modern />}></Route>
              <Route path="404-classic" element={<Error404Classic />}></Route>
              <Route path="504-modern" element={<Error504Modern />}></Route>
              <Route path="504-classic" element={<Error504Classic />}></Route>
            </Route>
            <Route path="*" element={<Error404Modern />}></Route>
            <Route path="invoice-print/:invoiceId" element={<SubsInvoicePrint />}></Route>
            <Route path="invoice-print/:invoiceId" element={<InvoicePrint />}></Route>
          </Route>

        </Routes>
      </Suspense>

    </>
  );
};
export default Pages;
