export const formatDate = dateString => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
}

export function formatMongoDate(mongoDate) {
    const date = new Date(mongoDate);
    return date.toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true });
}



export function formatDateForTable(mongoDateStr) {

    try {

        const date = new Date(mongoDateStr);
        const getMonthName = (monthIndex) => {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return months[monthIndex] || "Unknown";
        };

        const formatTime = (date) => {
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const period = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12 || 12;
            const minutesStr = minutes < 10 ? '0' + minutes : minutes;
            return `${hours}:${minutesStr}${period}`;
        };

        const formattedDate = `${date.getDate()} ${getMonthName(date.getMonth())}`;
        const formattedTime = formatTime(date);
        return `${formattedDate} ${formattedTime}`;

    } catch (error) {
        console.error("Error occurred while formatting date:", error);
        return "Invalid Date";
    }

}

export const maskString = str => (str.length <= 8 ? str : `${str.slice(0, 4)}...${str.slice(-4)}`);