
import axios from 'axios';
import { server } from '../store.js';

export const getMyOrders = _ => async dispatch => {

    try {

        dispatch({
            type: "getMyOrdersRequest",
        });

        const { data } = await axios.get(`${server}/order/myOrders`, {
            withCredentials: true,
        });
        
        dispatch({
            type: "getMyOrdersSuccess",
            payload: data.data,
        });

    } catch (error) {
        dispatch({
            type: "getMyOrdersFail",
            payload: error.response.data.message || 'network error',
        });
    }

}