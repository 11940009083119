import axios from 'axios';
import { server } from '../store';

export const createPayout = e => async dispatch => {

    try {

        dispatch({
            type: "createPayoutRequest",
        });

        const { data } = await axios.post(`${server}/address/new`, e, {
            withCredentials: true,
        });

        dispatch({
            type: "createPayoutSuccess",
            payload: data.data,
        });

    } catch (error) {
        dispatch({
            type: "createPayoutFail",
            payload: error.response.data.message || 'network error',
        });
    }

}

export const getPayoutWallet = _ => async dispatch => {

    try {

        dispatch({
            type: "getPayoutWalletRequest",
        });

        const { data } = await axios.get(`${server}/address/getAll`, {
            withCredentials: true,
        });

        dispatch({
            type: "getPayoutWalletSuccess",
            payload: data.data,
        });

    } catch (error) {
        dispatch({
            type: "getPayoutWalletFail",
            payload: error.response.data.message || 'network error',
        });
    }

}

export const verifyPayout = (walletId, otp) => async dispatch => {

    try {

        dispatch({
            type: "verifyPayoutRequest",
        });

        const { data } = await axios.post(`${server}/address/verify/${walletId}`, { otp }, {
            withCredentials: true,
        });

        dispatch({
            type: "verifyPayoutSuccess",
            payload: data.message,
        });

    } catch (error) {
        dispatch({
            type: "verifyPayoutFail",
            payload: error.response.data.message || 'network error',
        });
    }
}

export const deletePayout = e => async dispatch => {

    try {



        dispatch({
            type: "deletePayoutRequest",
        });

        const { data } = await axios.put(`${server}/address/delete`, { walletId : e }, {
            withCredentials: true,
        });


        dispatch({
            type: "deletePayoutSuccess",
            payload: data.address,
        });

    } catch (error) {
        dispatch({
            type: "deletePayoutFail",
            payload: error.response.data.message || 'network error',
        });
    }
}

export const resendPayoutOtp = walletId => async dispatch => {

    try {

        dispatch({
            type: "resendPayoutOtpRequest",
        });

        const { data } = await axios.get(`${server}/address/resendOtp/${walletId}`, {
            withCredentials: true,
        });

        dispatch({
            type: "resendPayoutOtpSuccess",
            payload: data.message,
        });

    } catch (error) {
        dispatch({
            type: "resendPayoutOtpFail",
            payload: error.response.data.message || 'network error',
        });
    }
}