
import axios from "axios";
import { server } from "../store";

export const registerAction = formData => async dispatch => {

    try {

        dispatch({
            type: "registerRequest",
        });

        const { data } = await axios.post(`${server}/user/register`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
        });

        dispatch({
            type: "registerSuccess",
            payload: data.message,
        })

    } catch (error) {
        dispatch({
            type: "registerFail",
            payload: error.response.data.message || 'Network Error',
        });
    }
};

export const login = (email, password) => async dispatch => {
    try {

        dispatch({
            type: "loginRequest",
        });

        const { data } = await axios.post(
            `${server}/user/login`,
            {
                email,
                password,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            }
        );

        dispatch({
            type: "loginSuccess",
            payload: data.message,
        });


    } catch (error) {
        dispatch({
            type: "loginFail",
            payload: error.response.data.message,
        });
    }
};

export const loadUser = () => async (dispatch) => {
    try {
        dispatch({
            type: "loaduserRequest",
        });
        const { data } = await axios.get(`${server}/user/me`, {
            withCredentials: true,
        });

        dispatch({
            type: "loaduserSuccess",
            payload: data.user || null,
        });

    } catch (error) {
        dispatch({
            type: "loaduserFail",
            payload: error.response.data.message || 'network error'
        });
    }
};

export const logout = () => async (dispatch) => {
    try {
        
        dispatch({
            type: "logoutRequest",
        });

        const { data } = await axios.get(`${server}/user/logout`, {
            withCredentials: true,
        });

        dispatch({
            type: "logoutSuccess",
            payload: data.message,
        });

    } catch (error) {
        dispatch({
            type: "logoutFail",
            payload: error.response.data.message,
        });
    }
};

export const accountVerification = otp => async (dispatch) => {
    try {
        dispatch({
            type: "verificationRequest",
        });
        const { data } = await axios.post(`${server}/user/verify`, { otp }, {
            withCredentials: true,
        });

        dispatch({
            type: "verificationSuccess",
            payload: data.message,
        });
    } catch (error) {
        dispatch({
            type: "verificationFail",
            payload: error.response.data.message,
        });
    }
};