import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { getMyOrders } from '../../../../../redux/actions/orderActions';

import MyOrders from './MyOrders';

const Index = () => {

    const dispatch = useDispatch();
    const { orders } = useSelector(state => state.orders);

    useEffect(() => {
        dispatch(getMyOrders())
    }, [dispatch])

    return orders && <MyOrders orders={orders} />


}

export default Index