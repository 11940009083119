
import { createReducer } from "@reduxjs/toolkit";

export const payoutReducer = createReducer({}, builder => {

    builder
        .addCase('addPayoutWalletRequest', (state, action) => {
            state.selectedCoin = action.payload
        })
        .addCase('createPayoutRequest', (state, action) => {
            state.loading = true
        })
        .addCase('getPayoutWalletRequest', (state, action) => {
            state.loading = true
        })
        .addCase('verifyPayoutRequest', (state, action) => {
            state.loading = true
        })
        .addCase('resendPayoutOtpRequest', (state, action) => {
            state.loading = true
        })
        .addCase('deletePayoutRequest', (state, action) => {
            state.loading = true
        })

    builder
        .addCase('createPayoutSuccess', (state, action) => {
            state.loading = false
            state.newWalletId = action.payload
            state.message = 'please verify using e-mail'
        })
        .addCase('getPayoutWalletSuccess', (state, action) => {
            state.loading = false
            state.wallets = action.payload
        })
        .addCase('verifyPayoutSuccess', (state, action) => {
            state.loading = false
            state.message = action.payload
        })
        .addCase('resendPayoutOtpSuccess', (state, action) => {
            state.loading = false
            state.message = action.payload
        })
        .addCase('deletePayoutSuccess', (state, action) => {
            state.loading = false
            state.message = action.payload
            state.wallets = state.wallets.filter(wallet => wallet._id !== action.payload._id)
        })

    builder
        .addCase('createPayoutFail', (state, action) => {
            state.loading = false
            state.error = action.payload
        })
        .addCase('getPayoutWalletFail', (state, action) => {
            state.loading = false
            state.error = action.payload
        })
        .addCase('verifyPayoutFail', (state, action) => {
            state.loading = false
            state.error = action.payload
        })
        .addCase('resendPayoutOtpFail', (state, action) => {
            state.loading = false
            state.error = action.payload
        })



    builder.addCase('clearMessage', state => {
        state.message = null
    })
    builder.addCase('clearError', state => {
        state.error = null
    })
})