
import { createReducer } from "@reduxjs/toolkit"

export const orderReducer = createReducer({}, builder => {

    builder
        .addCase("getMyOrdersRequest", state => {
            state.loading = true;
        })
    builder
        .addCase("getMyOrdersSuccess", (state, action) => {
            state.loading = false;
            state.orders = action.payload;
        })
    builder
        .addCase("getMyOrdersFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    builder.addCase('clearError', state => {
        state.error = null;
    })

    builder.addCase('clearMessage', state => {
        state.message = null;
    })

})