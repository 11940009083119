import React, { useEffect, useState } from "react";

import { Icon } from "../../../../../components/Component";
import { UserAvatar } from "../../../../../components/Component";

import { CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../../../components/Component";

import { Link } from "react-router-dom";
import { findUpper } from "../../../../../utils/Utils";
import { formatMongoDate } from "../../../../../utils/helpers";

const TxTable = ({ orders }) => {

    const [data, setData] = useState(orders.orders || []);
    const [trans, setTrans] = useState("");

    useEffect(() => {

        let filteredData;

        if (trans === "Pending") {
            filteredData = orders.orders.filter((item) => item.orderStatus === "Pending");

        } else if (trans === "Success") {
            filteredData = orders.orders.filter((item) => item.orderStatus === "Success");

        } else {

            filteredData = orders.orders;
        }

        setData(filteredData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trans]);

    const DropdownTrans = () => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                    <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu end>
                    <ul className="link-list-plain">
                        <li>
                            <DropdownItem
                                tag="a"
                                href="#view"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                            >
                                View
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem
                                tag="a"
                                href="#invoice"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                            >
                                Invoice
                            </DropdownItem>
                        </li>
                        <li>
                            <DropdownItem
                                tag="a"
                                href="#print"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                            >
                                Print
                            </DropdownItem>
                        </li>
                    </ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };
    return (

        <React.Fragment>
            <div className="card-inner">
                <div className="card-title-group">
                    <CardTitle>
                        <h6 className="title">
                            <span className="me-2">Transaction</span>{" "}
                            <Link to={`/admin/history-payment`} className="link d-none d-sm-inline">
                                See History
                            </Link>
                        </h6>
                    </CardTitle>
                    <div className="card-tools">
                        <ul className="card-tools-nav">
                            <li className={trans === "Success" ? "active" : ""} onClick={() => setTrans("Success")}>
                                <a
                                    href="#Success"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Success</span>
                                </a>
                            </li>
                            <li className={trans === "Pending" ? "active" : ""} onClick={() => setTrans("Pending")}>
                                <a
                                    href="#pending"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>Pending</span>
                                </a>
                            </li>
                            <li className={trans === "" ? "active" : ""} onClick={() => setTrans("")}>
                                <a
                                    href="#all"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <span>All</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <DataTableBody className="border-top" bodyclass="nk-tb-orders">
                <DataTableHead>
                    <DataTableRow>
                        <span>Order No.</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                        <span>Customer</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                        <span>Date</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                        <span>Ref</span>
                    </DataTableRow>
                    <DataTableRow>
                        <span>Amount</span>
                    </DataTableRow>
                    <DataTableRow>
                        <span className="d-none d-sm-inline">Status</span>
                    </DataTableRow>
                    <DataTableRow>
                        <span>&nbsp;</span>
                    </DataTableRow>
                </DataTableHead>
                {data.map((item, idx) => {
                    return (
                        <DataTableItem key={idx}>
                            <DataTableRow>
                                <span className="tb-lead">
                                    <a href="#order">{item.orderId}</a>
                                </span>
                            </DataTableRow>
                            <DataTableRow size="sm">
                                <div className="user-card">
                                    <UserAvatar size="sm" theme={item.theme} text={findUpper(item['customerDetails']?.name || 'Unknown')}></UserAvatar>
                                    <div className="user-name">
                                        <span className="tb-lead">{item['customerDetails']?.name || 'Unknown'}</span>
                                    </div>
                                </div>
                            </DataTableRow>
                            <DataTableRow size="md">
                                <span className="tb-sub">{formatMongoDate(item.createdAt)}</span>
                            </DataTableRow>
                            <DataTableRow size="lg">
                                <span className="tb-sub text-primary">{item.ref || 'n/a'}</span>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="tb-sub tb-amount">
                                    {item.payPrice} <span>USD</span>
                                </span>
                            </DataTableRow>
                            <DataTableRow>
                                <Badge
                                    className="badge-dot badge-dot-xs"
                                    color={
                                        item.orderStatus === "Success" ? "success" : item.orderStatus === "Pending" ? "warning" : "danger"
                                    }
                                >
                                    {item.orderStatus}
                                </Badge>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-action">
                                <DropdownTrans />
                            </DataTableRow>
                        </DataTableItem>
                    );
                })}
            </DataTableBody>
        </React.Fragment>
    );
};
export default TxTable;
