import React, { useEffect } from 'react'
import { Steps, message } from 'antd';
import PersonalForm from './PersonalForm.js';
import VerificationForm from './VerificationForm.js';
import { useParams } from 'react-router';
import { Block } from '../../../../../components/Component.js';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Link } from 'react-router-dom'
import toast from 'react-hot-toast';

const Success = (props) => {

    return (
        <div className="d-flex justify-content-center align-items-center p-3">
            <div>
                <span className="text-center h6">
                    Thank you for submitting form
                </span>

                <div className='mt-1'>
                    <Link to={'/payout-settings'} ><strong>Back To Payout</strong> </Link>
                </div>
            </div>

        </div>
    )
}

const Wizard = () => {

    const dispatch = useDispatch()
    const { coinId } = useParams()
    const { currency } = useSelector(state => state.currencyAdmin)
    const { message, error } = useSelector(state => state.payoutWallet)
    const [coin, setCoin] = React.useState("")
    const [current, setCurrent] = React.useState(0)

    useEffect(() => {

        if (message) {
            toast.success(message)
            dispatch({
                type: 'clearMessage'
            })
        }

        if (error) {
            toast.error(error)
            dispatch({
                type: 'clearError'
            })
        }

    }, [message, error, dispatch])

    useEffect(() => {
        setCoin(currency?.find(item => item._id === coinId))
    }, [coinId, currency])

    return (
        <>
            {!currency && <Navigate to={'/'} />}
            <Block>
                <div>
                    <h5 className='ff-base fw-bold '>{coin?.currencyName}</h5>
                    <span className='text-muted'>This payout setting can be used for <strong>{coin?.currencyName}</strong> ( <strong>{coin?.currencyNetwork}</strong> ) payouts.</span>
                </div>
            </Block>
            <Block>
                <div className='mb-5'>
                    <Steps
                        size="small"
                        current={current}
                        items={[
                            {
                                title: 'Crypto Wallet Information',
                            },
                            {
                                title: 'Confirmation',
                            },
                            {
                                title: 'Confirming',
                            },
                        ]}
                    />
                </div>
            </Block>
            <Block>
                {
                    current === 0 ? <PersonalForm next={() => setCurrent(1)} currency={coinId} /> :
                        current === 1 ? <VerificationForm next={() => setCurrent(3)} /> :
                            <Success />

                }
            </Block>
        </>
    )
}

export default Wizard