import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/userReducer.js";
import { apikeyReducer } from "./reducers/apikeyReducer.js";
import { adminCurrencyReducer } from "./reducers/admin/currencyReducer.js";
import { payoutReducer } from "./reducers/payoutReducer.js";
import { orderReducer } from "./reducers/orderReducer.js";
import { userReducer as adminUserReducer } from "./reducers/admin/userReducer.js";
import { adminDashboardReducer } from "./reducers/admin/dashboardReducer.js";

export const store = configureStore({

    reducer: {

        user: userReducer,
        apiKey: apikeyReducer,
        currencyAdmin: adminCurrencyReducer,
        payoutWallet: payoutReducer,
        orders: orderReducer,
        adminUser: adminUserReducer,
        adminDashboard: adminDashboardReducer
        
    },

});

// export const server = 'http://localhost:4000/api/v1'
export const server = 'https://payments.arbitfx.com/api/v1'
