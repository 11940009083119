
import { createReducer } from "@reduxjs/toolkit";

export const userReducer = createReducer({ isAuthenticated: null }, builder => {

    builder
        .addCase("loginRequest", state => {
            state.loading = true;
        })
        .addCase("registerRequest", state => {
            state.loading = true;
        })
        .addCase('loaduserRequest', state => {
            state.loading = true;
        })
        .addCase("logoutRequest", state => {
            state.loading = true;
        })
        .addCase("forgotPassRequest", state => {
            state.loading = true;
        })
        .addCase('verificationRequest', state => {
            state.loading = true;
        });

    builder
        .addCase('loginSuccess', (state, action) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.message = action.payload;
        })
        .addCase('loaduserSuccess', (state, action) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = action.payload;
        })
        .addCase('registerSuccess', (state, action) => {
            state.loading = false;
            state.message = action.payload;
        })
        .addCase('logoutSuccess', (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.message = action.payload;
        })
        .addCase('verificationSuccess', (state, action) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.message = action.payload;
            state.accVerified = true;
        });


    builder
        .addCase('loginFail', (state, action) => {

            state.loading = false;
            state.isAuthenticated = false;
            state.error = action.payload;

        })
        .addCase('loaduserFail', (state, action) => {

            state.loading = false;
            state.isAuthenticated = false;
            state.error = action.payload;

        })
        .addCase('registerFail', (state, action) => {

            state.loading = false;
            state.isAuthenticated = false;
            state.error = action.payload;

        })
        .addCase('logoutFail', (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.error = action.payload;
        })
        .addCase('verificationFail', (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.error = action.payload;
        });

    builder.addCase("clearError", (state) => {
        state.error = null;
    });

    builder.addCase("clearMessage", (state) => {
        state.message = null;
    });
});
