
import React, { useEffect, useState } from "react";
import { CardTitle } from "reactstrap";
import { Icon, TooltipComponent } from "../../../../../components/Component";
import { BarChart } from "../../../../../components/partials/charts/default/Charts";

const AvgSell = ({ txdata }) => {

    const [successtx, setSuccesstx] = useState([])

    useEffect(() => {
        const orders = txdata?.orders || []
        const total = orders.filter(e => e.orderStatus === 'Success').reduce((acc, curr) => acc + curr.payPrice, 0)
        setSuccesstx(total || 0)
    }, [txdata])

    return (
        <React.Fragment>
            {" "}
            <div className="card-title-group align-start mb-2">
                <CardTitle>
                    <h6 className="title">Total Collection</h6>
                </CardTitle>
                <div className="card-tools">
                    <TooltipComponent
                        icon="help-fill"
                        iconClass="card-hint"
                        direction="left"
                        id="Tooltip-3"
                        text="Daily Avg. subscription"
                    />
                </div>
            </div>
            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                <div className="nk-sale-data">
                    <span className="amount">{successtx || 346.2}</span>
                    <span className="sub-title">
                        <span className="change up text-success">
                            <Icon name="arrow-long-up" />
                            2.45%
                        </span>
                        since last week
                    </span>
                </div>
                <div className="nk-sales-ck">
                    <BarChart />
                </div>
            </div>
        </React.Fragment>
    );
};
export default AvgSell;
