import { server } from "../../store";
import axios from "axios";



export const getCurrencyAdmin = _ => async dispatch => {

    try {

        dispatch({
            type: "getCurrencyRequestAdmin",
        })

        const { data } = await axios.get(`${server}/admin/currency`, {
            withCredentials: true,
        })

        dispatch({
            type: "getCurrencySuccessAdmin",
            payload: data.data,
        })

    } catch (error) {
        dispatch({
            type: "getCurrencyFailAdmin",
            payload: error.response.data.message || null,
        })
    }
}


export const blockUnblockCurrency = id => async dispatch => {

    try {

        dispatch({
            type: "blockUnblockCurrencyRequestAdmin"
        });

        const { data } = await axios.put(`${server}/admin/currency/${id}/block-unblock`, {}, {
            withCredentials: true,
        });

        console.log(data)

        dispatch({
            type: "blockUnblockCurrencySuccessAdmin",
            payload: data
        });

    } catch (error) {

        dispatch({
            type: "blockUnblockCurrencyFailAdmin",
            payload: error.response.data.message || 'network error'
        });
    }
}


export const getTxDetailsByCurrency = id => async dispatch => {

    try {

        dispatch({
            type: "getTxDetailsByCurrencyRequestAdmin"
        });

        const { data } = await axios.get(`${server}/admin/currency/${id}/txdetails`, {
            withCredentials: true,
        });

        console.log(data)

        dispatch({
            type: "getTxDetailsByCurrencySuccessAdmin",
            payload: data.data
        });

    } catch (error) {

        dispatch({
            type: "getTxDetailsByCurrencyFailAdmin",
            payload: error.response.data.message || 'network error'
        });
    }
}


export const addCurrency = data => async dispatch => {
    
        try {

            console.log(data)

            dispatch({
                type: "addCurrencyRequestAdmin"
            });
    
            const { data: response } = await axios.post(`${server}/admin/currency`, data, {
                withCredentials: true,
            });
    
            console.log(response)
    
            dispatch({
                type: "addCurrencySuccessAdmin",
                payload: response
            });
    
        } catch (error) {
    
            dispatch({
                type: "addCurrencyFailAdmin",
                payload: error.response.data.message || 'network error'
            });
        }
}