import React from 'react'
import { ThreeCircles } from 'react-loader-spinner'

const Index = () => {
    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <ThreeCircles
                height="100"
                width="100"
                color="black"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="Loading Please Wait"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />

            <div>
                <span style={{ fontSize: '1rem', fontWeight: 'bold' }}> Please Wait</span>
            </div>


        </div>
    )
}

export default Index