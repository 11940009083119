import React, { useEffect, useState } from "react";
import Head from "../../../../../layout/head/Head";
import Content from "../../../../../layout/content/Content";
import RecentActivity from "../../../../../components/partials/default/recent-activity/Activity";
import Support from "../../../../../components/partials/default/support-request/Support";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";

import {

  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween

} from "../../../../../components/Component";

import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getCurrencyAdmin, getTxDetailsByCurrency } from "../../../../../redux/actions/admin/currencyAction";
import ActiveSubs from "./ActiveSubs";
import TxTable from "./TxTable";
import AvgSell from "./AvgSell";


const CoinDashboard = () => {

  const dispatch = useDispatch();
  const [sm, updateSm] = useState(false);
  const [coin, setCoin] = useState(null)
  const { currency, txDetails, loading } = useSelector(state => state.currencyAdmin)

  const { coinId } = useParams();

  useEffect(() => {

    if (!currency) {
      dispatch(getCurrencyAdmin())
    }

    const [coin] = currency?.filter(item => item._id === coinId) || []

    setCoin(coin)
  }, [coinId, currency, coin, dispatch])


  useEffect(() => {

    dispatch(getTxDetailsByCurrency(coinId))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])


  return (

    <React.Fragment>
      <Head title="coin details"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {coin?.currencySymbol}
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>{coin ? `All The Details Of ${coin.currencySymbol} ${coin.currencyNetwork} Payouts` : 'No coin details available'}</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                          <Icon className="d-none d-sm-inline" name="calender-date" />
                          <span>
                            <span className="d-none d-md-inline">Last</span> 30 Days
                          </span>
                          <Icon className="dd-indc" name="chevron-right" />
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#!"
                              >
                                <span>Last 30 days</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#dropdownitem"
                              >
                                <span>Last 6 months</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#dropdownitem"
                              >
                                <span>Last 3 weeks</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary">
                        <Icon name="reports" />
                        <span>Reports</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col sm="6">
              <PreviewAltCard>
                {!loading && <ActiveSubs txdata={txDetails} />}
              </PreviewAltCard>
            </Col>
            <Col sm="6">
              <PreviewAltCard>
                {!loading && <AvgSell txdata={txDetails} />}
              </PreviewAltCard>
            </Col>

            <Col size="12">
              <Card className="card-bordered card-full">
                {txDetails ? <TxTable orders={txDetails} /> : '`No transaction details available`'}
              </Card>
            </Col>

            <Col lg="6">
              <Card className="card-bordered card-full">
                <RecentActivity />
              </Card>
            </Col>

            <Col lg="6">
              <Card className="card-bordered h-100">
                <Support />
              </Card>
            </Col>

          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default CoinDashboard;
