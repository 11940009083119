import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({
    
    isAuthenticated,
    children,
    adminOnly,
    admin

}) => {

    if (!isAuthenticated) {
        return <Navigate to={'/auth-login'} />
    }

    if (adminOnly && !admin) {
        return <Navigate to={'/errors/504-modern'} />
    }

    return children || <Outlet />;
};

export default ProtectedRoute;





