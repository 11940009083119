
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../../../components/Component";
import { createPayout } from "../../../../../redux/actions/payoutActions";
import toast from "react-hot-toast";

const PersonalForm = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const { message, error } = useSelector(state => state.payoutWallet)

    useEffect(() => {

        if (message){
            toast.success(message)
            dispatch({
                type: 'clearMessage'
            })
            props.next()
        }
            

        if (error){
            toast.error(error)
            dispatch({
                type: 'clearError'
            })
        }
          
    }, [dispatch, error, message, props])

    const submitForm = (data) => {
        data.currency = props.currency
        dispatch(createPayout(data))
    };

    return (
        <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
            <Row className="gy-1">
                <Col md="6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="first-name">
                            Title
                        </label>
                        <div className="form-control-wrap">
                            <input
                                type="text"
                                id="first-name"
                                className="form-control"
                                {...register('title', { required: true })}
                                placeholder="Title for your payout setting"
                            />
                            {errors.title && <span className="invalid">This field is required</span>}
                        </div>
                    </div>
                </Col>

                <Col md="6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="callBack">
                            Callback URL
                        </label>
                        <div className="form-control-wrap">
                            <input
                                type="text"
                                id="callBack"
                                className="form-control"
                                {...register('callBack', { required: true })}
                                placeholder="Title for your payout setting"
                            />
                            {errors.title && <span className="invalid">This field is required</span>}
                        </div>
                    </div>
                </Col>

                <Col md="12">
                    <div className="form-group">
                        <label className="form-label" htmlFor="address">
                            Address
                        </label>
                        <div className="form-control-wrap">
                            <input
                                type="text"
                                className="form-control"
                                {...register('address', {
                                    required: true
                                })}

                            />
                            {errors.address && <span className="invalid">This field is required</span>}
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="actions clearfix">
                <ul>
                    <li>
                        <Button outline color="secondary" type="submit">
                            <span className="mr-1">Next</span> <Icon name="arrow-right" />
                        </Button>
                    </li>
                </ul>
            </div>
        </form>
    );
};


export default PersonalForm