
import { createReducer } from "@reduxjs/toolkit";


export const userReducer = createReducer({}, builder => {


    builder
        // get all users
        .addCase("getAllUsersRequest", state => {
            state.loading = true;
        })
        .addCase("getAllUsersSuccess", (state, action) => {
            state.loading = false;
            state.users = action.payload;
        })
        .addCase("getAllUsersFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        // block unblock user
        .addCase("blockUnblockUserRequest", state => {
            state.loading = true;
        })
        .addCase("blockUnblockUserSuccess", (state, action) => {
            state.loading = false;
            state.message = action.payload.message;
            state.users = state.users.map(user => user._id === action.payload.company._id ? action.payload.company : user);
        })
        .addCase("blockUnblockUserFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    
    builder.addCase("clearMessage", state => {  state.message = null; })

});