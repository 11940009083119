import React, { useState } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Link, Navigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Icon } from '../../../../../components/Component';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { resendPayoutOtp, verifyPayout } from '../../../../../redux/actions/payoutActions';
import { useNavigate } from 'react-router-dom';

const VerificationForm = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)
  const { newWalletId } = useSelector(state => state.payoutWallet)

  const [otp, setOtp] = useState('');
  const handleChange = (code) => setOtp(code);

  const verifyOtp = () => {

    if (otp.length !== 6) {
      return toast.error('Invalid OTP')
    }

    dispatch(verifyPayout(newWalletId, otp))
    props.next();
  }

  const resendOtp = _ => {
    dispatch(resendPayoutOtp(newWalletId))
  }

  return (
    <form className="content clearfix">
      <Row className="gy-4">
        <Col md="16">
          <h6>Email Confirmation</h6>
          <span>Confirmation code was sent to <span className='fw-bold ff-alt'>{user?.email}</span> </span>
          <div>
            <span>Something went wrong ? <Link to={'/support'}> contact support team </Link> </span>
          </div>
          <div className='mt-2 fw-medium'>
            <span>Enter the 6-digit code from the email</span>
          </div>
          <div className="form-group mt-2">
            <div className="form-control-wrap">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                renderSeparator={<span>-</span>}
                isInputNum={true}
                shouldAutoFocus={true}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  border: "1px solid black",
                  width: "2.2rem",
                  height: "2rem",
                  fontSize: "12px",
                  color: "unset",
                  fontWeight: "400",
                  // caretColor: "blue"
                }}
                focusStyle={{
                  border: "1px solid black",
                  outline: "none"
                }}
              />
            </div>
          </div>
          <span className='text-primary fw-bold' style={{ cursor: 'pointer' }} onClick={resendOtp}>Resend OTP <Icon name={'arrow-right'} /> </span>
        </Col>
        <Col md={3}>
          <Button disabled={otp.length < 6 ? true : false} outline color='primary' onClick={verifyOtp}> continue </Button>
        </Col>
      </Row>
      <hr />
      <div >
        <Button outline color="light" onClick={() => navigate('/payout-settings')}>
          <Icon name="arrow-long-left" /> Back to payout settings
        </Button>
      </div>
    </form>
  );
};

export default VerificationForm