import { createReducer } from "@reduxjs/toolkit";

export const apikeyReducer = createReducer({}, builder => {

    builder
        .addCase("generateKeyRequest", state => {
            state.loading = true;
        })
        .addCase("getApiKeyRequest", state => {
            state.loading = true;
        })
        .addCase("deleteApiKeyRequest", state => {
            state.loading = true;
        })
        .addCase("blockUnblockApiKeyRequest", state =>{
            state.loading = true;
        })
    builder
        .addCase("generateKeySuccess", (state, action) => {
            state.loading = false;
            state.message = action.payload;
        })
        .addCase("getApiKeySuccess", (state, action) => {
            
            state.loading = false;
            state.keys = action.payload;
        })
        .addCase("deleteApiKeySuccess", (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.keys = state.keys.filter(key => key._id !== action.payload);
        })
        .addCase("blockUnblockApiKeySuccess", (state, action) => {
            state.loading = false;
            state.message = action.payload;
        })
    builder
        .addCase("generateKeyFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase("getApiKeyFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.keys = [];
        })
        .addCase("deleteApiKeyFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase("blockUnblockApiKeyFail", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    builder.addCase("clearError", (state) => {
        state.error = null;
    });

    builder.addCase("clearMessage", (state) => {
        state.message = null;
    });

})