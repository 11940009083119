
import { createReducer } from "@reduxjs/toolkit";


export const adminCurrencyReducer = createReducer({}, builder => {

    builder
        .addCase("getCurrencyRequestAdmin", state => {
            state.loading = true;
        })
        .addCase("getCurrencySuccessAdmin", (state, action) => {
            state.loading = false;
            state.currency = action.payload;
        })
        .addCase("getCurrencyFailAdmin", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase("blockUnblockCurrencyRequestAdmin", state => {
            state.loading = true;
        })
        .addCase("blockUnblockCurrencySuccessAdmin", (state, action) => {

            state.loading = false;
            state.message = action.payload.message;
            state.currency = state.currency.map(currency => currency._id === action.payload.currency._id ? action.payload.currency : currency);

        })
        .addCase("blockUnblockCurrencyFailAdmin", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase('getTxDetailsByCurrencyRequestAdmin', state => {
            state.loading = true;
        })
        .addCase('getTxDetailsByCurrencySuccessAdmin', (state, action) => {
            state.loading = false;
            state.txDetails = action.payload;
        })
        .addCase('getTxDetailsByCurrencyFailAdmin', (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        .addCase('addCurrencyRequestAdmin', state => {
            state.loading = true;
        })
        .addCase('addCurrencySuccessAdmin', (state, action) => {
            state.loading = false;
            state.currency = [...state.currency, action.payload.data];
            state.message = 'Currency added successfully';
        })
        .addCase('addCurrencyFailAdmin', (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })


    builder.addCase("clearError", (state) => {
        state.error = null;
    });

    builder.addCase("clearMessage", (state) => {
        state.message = null;
    });

});