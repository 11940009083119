import Router from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import { BrowserRouter } from "react-router-dom";

const App = () => {

  return (
    <ThemeProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  )

}
export default App;